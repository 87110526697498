.chat-bot-container {
    .responsive-chat-list{
        .search-section {
            background: white;
            margin-bottom: 0.5rem;
            padding: 0.25rem 0.5rem;
            border-radius: 12px;

            .new-chat-icon{   
                display: flex;                 
                svg{
                    font-size: 20px;
                    background: #494956;                    
                    padding: 10px;
                    color: white;
                    border-radius: 5px;
                }
            }
        }
    }
}

.conversation-list{

    .header{
        font-weight: 600;
        font-size: 18px;
        background: #e5e5e5;
    }
    .search-section {
        padding: 0 0.5rem;
        .search-textbox {
            .MuiInputBase-root {
                border-radius: 25px;
                fieldset{
                    border-color: #008000;
                }
            }
        }
        // .new-chat-icon{                    
        //     svg{
        //         font-size: 20px;
        //         background: #494956;                    
        //         padding: 10px;
        //         color: white;
        //         border-radius: 5px;
        //     }
        // }
    }
    .list-scroll{
        max-height: calc(100vh - 9rem); //9rem
        min-height: calc(100vh - 9rem); //search - hide
        overflow-y: auto;
    }
    .initial-view{
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: calc(100vh - 13rem);
        min-height: calc(100vh - 13rem);

        .inner-div{
            text-align: center;
            .chatbot-image{
                max-width: 150px;
            }
            .welcome-text{
                font-style: oblique;
                font-size: 18px;
                font-weight: 600;
            }
            .start-text{
                font-size: 16px;
                margin-bottom: 0.5rem;
            }
            .start-subtext{
                font-size: 14px;
                color: #97999a;
            }
            .start-button{
                font-size: 14px;
                text-transform: capitalize;
                width: 235px;
                height: 35px;
                border-radius: 4px;
                background: #494956;
                font-weight: 600;
            }
        }
        
    }   
    .conversation-item{
        margin-bottom: 0.75rem;
        border: none;
        box-shadow: 0px 0px 10px #abaaaa;
        cursor: pointer;

        &.active-chat {
                color: white;
                background: #008dc3;
        
                .title {
                    color: white;
                }
        
                .timestamp {
                    color: white;
                }
            }

        .title{
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0;
            color: #32323B;
        }
        
        .timestamp {
            font-size: 10px;
            font-weight: 800;
            text-align: left;
            width: 100%;
            margin-bottom: 0px;
            margin-top: 0.5rem;
            color: #6C6C7F;
            // position: absolute;
            right: 12px;
        }
        .last-message{
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0;
            color: #97999a;
        }
        .chatbot-icon{
            max-width: 50px;
            // box-shadow: 0px 0px 5px #245061;
            border-radius: 50%;
            padding: 0.25rem;
            margin-right: 0.5rem;
        }
    }
}

.conversation-view{
    .header{
        font-weight: 600;
        font-size: 18px;
        background: #e5e5e5;
    }

    .conversation-box{
        max-height: calc(100vh - 13.5rem);
        min-height: calc(100vh - 13.5rem);    
        padding: 0 !important;    
        .conversation-inner{
            position: relative;
            padding: 0.75rem;
            overflow-y: auto;
            overflow-x:hidden;
            min-height: calc(100vh - 13.5rem);
            max-height: calc(100vh - 13.5rem);
            background-image: url(../../assets/image/bg-1.jpg);
            background-position: center;
            background-size: contain;
        }
    }

    .initial-view{
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: calc(100vh - 8.5rem);
        min-height: calc(100vh - 8.5rem);

        .inner-div{
            text-align: center;
            .chatbot-image{
                max-width: 150px;
            }
            .welcome-text{
                font-style: oblique;
                font-size: 18px;
                font-weight: 600;
            }
            .start-text{
                font-size: 16px;
                margin-bottom: 0.5rem;
            }
            .start-subtext{
                font-size: 14px;
                color: #97999a;
            }
            .start-button{
                font-size: 14px;
                text-transform: capitalize;
                width: 235px;
                height: 35px;
                border-radius: 4px;
                background: #494956;
                font-weight: 600;
            }
        }
        
    }

    .message-image{
        width: 100%;
        max-width: 300px;
    }

    .message-server {
        background: #008dc3;
        max-width:400px;
        width: fit-content;
        padding: 0.25rem 0.5rem;                
        border-radius: 0rem 0.25rem 0.25rem 0.25rem;
        position: relative;
        margin-bottom: 0.5rem;
        white-space: pre-line;
        pre, p { 
            text-align: left;
            font-size: 14px;              
            color: #ffffff;
            -webkit-text-fill-color: #ffffff;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0);  
            margin-bottom: 0;
        }

        &:after {
            content: " ";
            position: absolute;
            left: -7px;
            top: 0px;
            border-top: 0px solid transparent;
            border-right: 8px solid #008dc3;
            border-left: none;
            border-bottom: 8px solid transparent;
        }
         
        .new-tab-button-icon{
            color: white;
            cursor: pointer;
            font-size: 14px;
            margin-right: 0.25rem;
        }

        .timestamp {
            font-size: 9px;
            text-align: right;
            // width: 100%;

            svg{
                color: #ffffff; 
                -webkit-text-fill-color: #ffffff;
            }
        }
       
    }

    .message-client{
        background: #1ac16c;
        max-width: 400px;
        width: fit-content;
        padding: 0.5rem 0.5rem;
        border-radius: 0.25rem 0rem 0.25rem 0.25rem;
        margin-left: auto;
        position: relative;
        margin-bottom: 0.5rem;
        white-space: pre-line;
        pre, p { 
            text-align: left;
            font-size: 14px;                
            color: #ffffff; 
            -webkit-text-fill-color: #ffffff;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0); 
            margin-bottom: 0;  
        }

        &:after {
            content: " ";
            position: absolute;
            right: -7px;
            top: 0px;
            border-top: 0px solid transparent;
            border-right: none;
            border-left: 8px solid #1ac16c;
            border-bottom: 8px solid transparent;
        }
        .timestamp {
            font-size: 9px;
            text-align: right;
            width: 100%;
            margin-top: 0.25rem;

            svg{
                color: #ffffff; 
                -webkit-text-fill-color: #ffffff;
            }
        }
    }
}

.main-container{
    padding: 0.5rem;
}

.card-footer{
    padding: 0.5rem !important;
    .conversation-bottom-section{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .message-area{
            width: 90%;
            margin-right: 0.5rem;
        }
        .message-send-button{
            max-width: 10%;
        }
    }
}

.icon-button{
    width: 40px;
    height: 40px;  
    margin-right: 0.25rem !important;
    padding: 0.75rem;
    border-radius: 50%;
    border: none;

    .p-button-label{
        visibility: hidden;
    }
    svg{
        width: 18px;
    }

    &.send-message {
        background-color: #2e7d32;
        color: white;

        &:hover{
            background-color: #2e7d32;
        }
    }
    &.attach-icon{
        background-color: #493cab;
        color: white;

        &:hover{
            background-color: #493cab;
        }
    }
    &.document-icon{
        background-color: #5f67cc;
        color: white;

        &:hover{
            background-color: #5f67cc;
        }
    }
    &.image-icon{
        background-color: #d3396d;
        color: white;

        &:hover{
            background-color: #d3396d;
        }
    }
    &.audio-icon{
        background-color: #f17a02;
        color: white;

        &:hover{
            background-color: #f17a02;
        }
    }
}

.attachment-section{
    display: flex;
    .icon-button{
        // margin-right: 0.5rem !important;
    }

}
.chat-bot-container{
    .responsive-chat-back-icon{
        display: none;
    }
}

.upload-picture{
    .uploaded-image{
        width: 100%;
    }
    .pdf-image{
        max-width: 180px;
    }
}

#menu-appbar{
    .MuiList-root.MuiMenu-list{
        padding: 0;
    }
}

@media (max-width: 595px) {
    .conversation-list {
        .list-scroll {
            max-height: calc(100vh - 8.5rem); //9.5 search - hide
            min-height: calc(100vh - 8.5rem);
        }
        .initial-view{
            max-height: calc(100vh - 8.5rem);
            min-height: calc(100vh - 8.5rem);
        }    
        /* inital view */
        &.card{
            border: none;
        }

        .header{
            display: none;
        }
        // .search-section {
        //     .search-textbox {
        //         .MuiInputBase-root {
        //             border-radius: 12px;
        //             fieldset{
        //                 // border-color: #008000;
        //                 border: 1px solid #E1E1E5
        //             }
        //         }
        //     }
           
        // }

        .card-body{
            padding: 0px;
            .conversation-item {
                margin-bottom: 4px;
                box-shadow: none;
                // border-bottom: 1px solid #008000;
                border-bottom: 1px solid #F1F3F6;
                .chatbot-icon {
                    max-width: 50px;
                }
            }
        }       


        /* End */
    }
    .conversation-view {
        border: none;
        .header{
            font-size: 16px;
            background: #ffffff;
            border-bottom: 2px solid #f1f3f6;
        }
        .conversation-box {
            min-height: calc(100vh - 13rem);
            max-height: calc(100vh - 13rem);
            .conversation-inner {
                min-height: calc(100vh - 13rem);
                max-height: calc(100vh - 13rem);
            }
        }
        .message-server,
        .message-client {
            max-width: 85%;
            min-width: 150px;
        }
    }
    .chat-bot-container {
        .responsive-chat-list{
            .search-section {
                background: white;
                margin-bottom: 0.5rem;
                padding: 0.25rem 0.5rem;
                border-radius: 12px;

                .new-chat-icon{                    
                    svg{
                        font-size: 20px;
                        background: #494956;                    
                        padding: 10px;
                        color: white;
                        border-radius: 5px;
                    }
                }
            }
            &.chat-open{
                display: block;
            }
            &.chat-close{
                display: none;
            }
        }
        .responsive-chat-message{
            &.chat-open{
                display: block;
            }
            &.chat-close{
                display: none;
            }
        }
        .responsive-chat-back-icon{
            display: block;
            margin-right: 0.5rem;
            cursor: pointer;
            span{
                display: flex;
            }
        }
    }
}

/* Preloader */

.chat-conversation-loader{
    width: 100%;
    position: relative;
    img{
        width: 100%;
        max-width: 275px;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% 35%;
    }
}


/*  */

.message-loader {
    margin-top: 1rem;
    .loader {
            margin-top: 4px;
            width: fit-content;
            background: #959393;
            border-radius: 10px;
            border-top-right-radius: 0px;
            padding: 0px 10px;
            padding-top: 10px;
            position: relative;
    
            &:after {
                content: " ";
                position: absolute;
                right: -7px;
                top: 0px;
                border-top: 0px solid transparent;
                border-right: none;
                border-left: 8px solid #959393;
                border-bottom: 8px solid transparent;
            }
        }

    .loader span {
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        margin-right: 4px;
        background: white;
        border-radius: 50%;
        animation: loader 0.5s infinite alternate;
    }

    .loader span:nth-of-type(2) {
        animation-delay: 0.2s;
    }

    .loader span:nth-of-type(3) {
        animation-delay: 0.3s;
    }

    @keyframes loader {
        0% {
            width: 10px;
            height: 10px;
            opacity: 0.9;
            transform: translateY(0);
        }
        100% {
            width: 10px;
            height: 10px;
            opacity: 0.3;
            transform: translateY(-11px);
        }
    }
}

.p-overlaypanel{
    border-radius: 6px 0px 6px 6px;
}
.p-overlaypanel-content{
    max-width: 384px;
    // min-width: 384px;
    padding: 1rem;
}

.fade.popover{
    transform: translate(550px, -500px);
}

.add-comment-button{
    padding: 0px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #ffffff;
    background: transparent;
    border: none;
    font-size: 14px;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 2px;
}
.rating-text{
    font-size: 14px;
    color: yellow;
}
@media (max-width: 595px) {
    .p-overlaypanel-content {
        max-width: 100%;
        min-width: auto;
    }
}

@keyframes typing {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.prompt-section-container{
    textarea{
        height: 100% !important;
    }
    
}
.prompt-suggestion-tip{
    p{
        margin-bottom: 4px;
        font-size: 13px;
    }
}

a {
    color: #003b97;
}