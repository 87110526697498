@import url('./assets/css/fonts.css');

.hide{
  display: none !important;
}

* {
  font-family: 'Smitch Sans' !important;
  /* font-family: 'PilcrowRounded' !important; */
  /* font-family: 'General Sans' !important; */
}

.p-toast-message-content{
  padding: 0.5rem 1rem;
}

.chat-bot-preloader{
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  background: #f7f5ffc4;
}


.scroll-bar-design {
  scrollbar-width: thin;
  /* scrollbar-height: thin; */
  scrollbar-color: #525cdd #ffffff;
}

.scroll-bar-design::-webkit-scrollbar {
  width: 10px;
  height:5px;
}

.scroll-bar-design::-webkit-scrollbar-track {
  background: #ffffff;
}

.scroll-bar-design::-webkit-scrollbar-thumb {
  background-color: #525cdd;
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.scroll-bar-design::-webkit-scrollbar-thumb:hover {
  background-color: #525cdd;
}