@font-face {
    font-family: 'Smitch Sans';
    font-style: normal;
    font-weight: 200;
    src: local('Smitch Sans'), url(../fonts/Smitch_Sans-Regular.ttf) format('woff');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  
  @font-face {
    font-family: 'Smitch Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Smitch Sans'), url(../fonts/Smitch-Sans-Bold.ttf) format('woff');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  
  @font-face {
    font-family: 'Smitch Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Smitch Sans'), url(../fonts/Smitch_Sans-Black.ttf) format('woff')
  }
  
  @font-face {
    font-family: 'Smitch Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Smitch Sans'), url(../fonts/Smitch_Sans-Medium.ttf) format('woff')
  }

  /* General Sans */
  
  @font-face {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 400;
    src: local('General Sans'), url(../fonts/General-Sans/GeneralSans-Regular.ttf) format('truetype')
  }

  @font-face {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    src: local('General Sans'), url(../fonts/General-Sans/GeneralSans-Medium.ttf) format('truetype')
  }

  @font-face {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 600;
    src: local('General Sans'), url(../fonts/General-Sans/GeneralSans-Semibold.ttf) format('truetype')
  } 
 
  @font-face {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 700;
    src: local('General Sans'), url(../fonts/General-Sans/GeneralSans-Bold.ttf) format('truetype')
  }

  /* Pilcrow Rounded */
  
  @font-face {
    font-family: 'PilcrowRounded';
    font-style: normal;
    font-weight: 500;
    src: local('PilcrowRounded'), url(../fonts/Pilcrow-Rounded/PilcrowRounded-Regular.ttf) format('truetype')
  }
  @font-face {
    font-family: 'PilcrowRounded';
    font-style: normal;
    font-weight: 500;
    src: local('PilcrowRounded'), url(../fonts/Pilcrow-Rounded/PilcrowRounded-Medium.ttf) format('truetype')
  }
  @font-face {
    font-family: 'PilcrowRounded';
    font-style: normal;
    font-weight: 600;
    src: local('PilcrowRounded'), url(../fonts/Pilcrow-Rounded/PilcrowRounded-Semibold.ttf) format('truetype')
  }
  @font-face {
    font-family: 'PilcrowRounded';
    font-style: normal;
    font-weight: 700;
    src: local('PilcrowRounded'), url(../fonts/Pilcrow-Rounded/PilcrowRounded-Bold.ttf) format('truetype')
  }
  @font-face {
    font-family: 'PilcrowRounded';
    font-style: normal;
    font-weight: 800;
    src: local('PilcrowRounded'), url(../fonts/Pilcrow-Rounded/PilcrowRounded-Heavy.ttf) format('truetype')
  }