@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    // margin: 0;
    // padding: 0;
    box-sizing: border-box;
}

html, body{
    // display: grid;
    height: 100%;
    width: 100%;
    background: #f1f3f6;
    // place-items: center;
    // background: -webkit-linear-gradient(left, #a445b2, #fa4299);
    
}

.login-container{
    background-color: rgb(233, 232, 232);
    height: 100vh;
    width: 100vw;
    background-image: url('../../assets/image/login-bg.jpg');
    background-position: center;
    background-size: cover;    
}

.wrapper{
    max-width: 390px;
    width: 350px;
    background-color: #ffffffb0;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0px 20px rgb(101 101 101);
    // overflow: hidden;
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
}

.wrapper .title-text{
    // display: flex;
    // width: 200%;
    width: 100%;
    text-align: center;
}

.wrapper .title-text .title{
    width: 100%;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #555;
}

.wrapper .form-container{
    width: 100%;
    overflow: hidden;
}

.form-container .slide-controls{
    display: flex;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    border: 1px solid lightgrey;
    overflow: hidden;
    margin: 30px 0 10px 0;
    border-radius: 10px;
    position: relative;
}

.login-logo{
  max-width: 100px;
}

.slide-controls .slide{
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    z-index: 1;
    transition: all .6s ease;        
}

.slide-controls .signup{
    color: #212121;
}

.slide-controls .slide-tab{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background: -webkit-linear-gradient(left, #a445b2, #fa4299);
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

input[type="radio"]{
    display: none;
}

#signup:checked ~ .slide-tab{
    left: 50%;
}

#signup:checked ~ .signup{
    color: #fff;
}

#signup:checked ~ .login{
    color: #212121;
}

.form-container .form-inner{
    display: flex;
    width: 200%;
}

.form-container .form-inner .login-form{
    width: 50%;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);;
}

.form-inner .login-form .field{
    height: 50px;
    width: 100%;
    margin-top: 20px;
}

.form-inner .login-form .field input{
    width: 100%;
    // height: 100%;
    // height: 35px;
    // outline: none;
    // font-size: 17px;
    // padding-left: 15px;
    // border-radius: 5px;
    // border: 1px solid lightgray;
    // border-bottom-width: 2px;
    // transition: all 0.4s ease;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code{
    padding-left: 0.25rem;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag{
    display: flex;
}
.p-inputotp{
    justify-content: space-around;
}
.p-inputotp-input{
    width: 3rem;
}

.form-inner .login-form .field input:focus{
    border-color: #fc83bb;
}

.form-inner .login-form .pass-link{
    margin-top: 5px;
}

.form-inner .login-form .pass-link a,
a{
    color: #003b97; //fa4299;    
    text-decoration: none;
}

.form-inner .login-form .signup-link{
    color: #212121;
    text-align: center;
    margin-top: 30px;
}

.form-inner .login-form .pass-link a:hover,
.form-inner .login-form .signup-link a:hover{
    text-decoration: underline;
}

.login-form .login-submit-button{
    background: -webkit-linear-gradient(left, #a445b2, #fa4299);
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding-left: 0;
    margin-top: 1rem;
    border: none;
    cursor: pointer;
    width: 100%;
    text-transform: capitalize;
}

.register-text{
    text-align: center;
    font-size: 14px;
    margin-top: 0.5rem;
    margin-bottom: 0;
    cursor: pointer;
    &:hover{
        font-weight: 600;
    }
}

.p-icon-field > .p-input-icon {
    position: absolute;
    top: 50%;
    margin-top: 0;
    transform: translateY(-50%);
}

@media (max-width: 595px) {
    .login-container {
        background-image: url('../../assets/image/login-bg-mobile.jpg');
        .wrapper {
            width: 90%;
            background-color: rgba(255, 255, 255, 0.69);
            padding: 30px;
            border-radius: 10px;
            box-shadow: 0 0px 20px rgb(101, 101, 101);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .title-text {
                    .title {
                        color: #000000;
                    }
                }

            .form-inner {
                    .login-form {
                        .field {
                            input {
                                font-size: 14px;
                            }
                        }
                        .login-submit-button{
                            font-size: 16px;
                        }
                    }
                }
        }
    }
}

.react-tel-input {
    .flag-dropdown{
        &.open {
            position: relative;
            .selected-flag{
                position: absolute;
                top: -20px;
            }
            .country-list {
                // width: 100%;
                position: fixed;
                margin: 0px 0px;
                padding: 0rem;
                width: calc(100% - 3.9rem);
                .search-emoji {
                    display: none;
                }
                .search {
                    padding: 0.5rem 0.25rem;
                }
                .search-box{
                    margin-left: 0;
                }
            }
        
        }
    }
    
    
}