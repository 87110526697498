.dh-top-menubar {
    background: #ffffff !important;

    .MuiToolbar-regular {
        background: #ffffff;
    }

    .app-bar-nav-icon {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        color: green;

        .dh-nav-logo {
            max-width: 50px;
            margin-right: 0.5rem;

            &.mobile {
                display: none;
            }

            &.desktop {
                display: block;
            }
        }
    }

}

@media (max-width: 595px) {
    .dh-top-menubar {
        background: #ffffff !important;

        .MuiToolbar-regular {
            background: #ffffff;
        }

        .app-bar-nav-icon {
            align-items: center;
            font-size: 20px;
            color: green;

            .dh-nav-logo {
                max-width: 35px;
                margin-right: 0.5rem;

                &.mobile {
                    display: block;
                }

                &.desktop {
                    display: none;
                }
            }
        }

    }
}

@media (min-width: 1240px) {
    .dh-top-menubar {
        .MuiContainer-root {
            max-width: 100%;
        }
    }
}